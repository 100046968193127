/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Settings from "../settings";
import Image from "../../images/company.png";
import noData from "../../images/NoData.svg";
import iExp from "../../images/icons/exp.svg";
import iSalary from "../../images/icons/salary.svg";
import iLocation from "../../images/icons/location.svg";
import iHome from "../../images/icons/home.svg";
import iUserGroup from "../../images/icons/user-group.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { Amplify, Auth } from "aws-amplify";
import Common from "../../utils/common";
import axios from "axios";
import "./buttonStyle.css";
import "./jobs.css";
import MobileHeader from "../mobileHeader";
import DesktopHeader from "../desktopHeader";
import ViewApplicant from "./appliedJobApplication";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import NewJob from "./addNewJob";
import Prefill from "./addPrefillNewJob";
import JobInfo from "./addJobInformation";
import PreviewJob from "./previewJob";
import Archieve from "../../images/Archieve.svg";
import Plus from "../../images/Normal_State_Plus.svg";
import PlusHover from "../../images/Hover_State_Plus.svg";
import ShimmerLoading from "../interview/shimmerloading";
import { Spinner } from "react-bootstrap";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;

const ContentWrapper = styled.div``;

const Wrapper = styled.div``;
const Wrappers = styled.div`
  display: flex;
  align-items: center;
`;
const JobDetail = styled.div``;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const JobTitle = styled.div`
  max-width: 200px;
  line-height: 23px;
  @media (max-width: 576px) {
    line-height: 17px;
    max-width: 170px;
  }
`;

const CompanyTitle = styled.div``;
const IconTitle = styled.div``;
const DateWrapper = styled.div``;
const DateWrapper1 = styled.div``;
const Text = styled.p`
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;

const HrLines = styled.div``;

const Applicant = styled.div``;
const HeaderLayout = styled.div``;

const NativeLink = styled.div``;
const ButtonLink = styled.div`
  cursor: pointer;
`;

const HeaderDownLayout = styled.div``;

const CardWrapper = styled.div``;
const PostedJob = styled.div`
  font-family: Inter-Medium;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: right;
  color: #504f8c;
  margin-top: 10px;
  @media (max-width: 576px) {
    font-size: 10px;
    margin-top: 3px;
  }
`;
const PostedOne = styled.div`
  font-family: Inter-Medium;
  font-size: 11px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 1px;
  text-align: right;
  color: #504f8c;
  @media (max-width: 576px) {
    font-size: 10px;
  }
`;
const Jobs = () => {
  const [user, setUsers] = useState("");
  const { state } = useLocation();
  const isCardActive = state?.isCardActive;
  const openNewPopup = state?.openNewJob;
  const [success, setSuccess] = useState(false);

  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch(() => {
        setUsers(null);
      });
  }, []);
  const [shouldUpdates, setShouldUpdates] = useState(false);
  const handleSubmit = () => {
    history.push(`/portal/view-applicants/${selectedJobData._id.toString()}`);
  };
  const history = useHistory();
  const [data, setData] = useState([]);
  const location = useLocation();

  const [jobId, setJobId] = useState("");
  const [openJobOne, setOpenJobOne] = useState(false);
  const [openJobTwo, setOpenJobTwo] = useState(false);
  const [openJobPreview, setOpenJobPreview] = useState(false);
  const [previewId, setPreviewId] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  // let date = new Date();
  // const [searchKeyword, setSearchKeyword] = useState("");
  function onSubmit() {
    // history.push("./addnew-job");
    setOpenJobOne(true);
  }

  const closeJobpageOn = (id) => {
    setOpenJobOne(false);
    setOpenJobTwo(true);
    setJobId(id);
  };

  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const getAllJob = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-manage-jobs`
      );
      console.log(response.data, "response");
      if (response.data) {
        const newJobs = response.data.job;

        setData(newJobs);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const [activeCard, setActiveCard] = useState(null);
  useEffect(() => {
    getAllJob();
  }, []);

  const closeAll = () => {
    setOpenJobOne(false);
  };

  const handleToggleChange = async (jobId) => {
    const job = data.find((item) => item._id === jobId);

    if (!job) {
      return;
    }

    const newStatus = job.status === "active" ? "archived" : "active";
    try {
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/manage/${job._id}/archive`,
        {
          status: newStatus,
        }
      );
      if (response.status === 200) {
        // Update the local job data with the new status
        const updatedData = data.map((item) =>
          item._id === job._id ? { ...item, status: newStatus } : item
        );
        setData(updatedData);
        toast.success("Job detail Archived Sucessfully");
        setPopupOpen(false);
      } else {
        console.error("Failed to update status");
      }
    } catch (err) {
      console.log("Error fetching archive details:", err);
    }
  };
  const [openJobOness, setOpenJobOness] = useState(false);
  const closeJobpageOnssYes = (id) => {
    setOpenJobOness(true);
    setOpenJobTwo(false);
    setJobId(id);
    setShouldUpdates(true);
  };
  const closeJobpageOnss = (id) => {
    setOpenJobOness(false);
    setOpenJobTwo(true);
    setJobId(id);
    setShouldUpdates(true);
  };

  const handleInputChange = (event) => {
    setSearchKeyword(event.target.value);
  };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const openPopup = (jobData) => {
    setSelectedJobData(jobData);
    setPopupOpen(true);
    setActiveCard(jobData._id);
  };

  // Function to close the popup
  const updateArchived = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.PUBLIC_URL}/xakal/manage/${selectedJobData._id}/archive`
      );
      getAllJob();
      setPopupOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [openSetting, setOpenSetting] = useState(false);

  const updatePreview = (id) => {
    setOpenJobTwo(false);
    setOpenJobPreview(true);
    setPreviewId(id);
  };
  const updatePreviews = () => {
    setOpenJobTwo(false);
    setOpenJobPreview(false);
  };
  const updateSavePreviews = (id) => {
    setOpenJobTwo(false);
    // setOpenJobPreview(true);
    setPreviewId(id);
  };

  const goToJobOne = () => {
    setOpenJobOness(true);
    setOpenJobTwo(false);
  };

  const goToJobtwo = () => {
    setOpenJobTwo(true);
    setOpenJobPreview(false);
  };
  const closeAllssActive = () => {
    setOpenJobOness(false);
    getAllJob();
  };

  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };
  const handleCall = () => {
    getAllJob();
    setOpenJobPreview(false);
  };
  const BackBtn = () => {
    history.push("./jobs");
  };

  const searchedKey = () => {};
  const [userRole, setUserRole] = useState(false);
  const prefill = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      );
      if (response && response.data && response.data.aboutOne) {
        setUserRole(response.data.aboutOne.userType.includes("hr"));
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    prefill();
  }, []);
  const [isScrolled, setIsScrolled] = useState(false);

  const isCardActives = (path) => {
    return location.pathname === path;
  };

  const [activeState, setActiveState] = useState(() => {
    // Initialize active state based on current path
    switch (location.pathname) {
      case "/portal/archievedJobs":
        return "archived";
      case "/portal/drafts":
        return "draft";

      default:
        return "manage-job"; // Default state for "/portal/jobs"
    }
  });
  const handleNavigationClick = (path, stateValue) => {
    history.push(path);
    setActiveState(stateValue);
  };
  useEffect(() => {
    if (isCardActive === "manage-job") {
      setActiveState(isCardActive);
      setOpenJobOne(openNewPopup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ToastContainer />

      <HeaderLayout className="header-layout">
        {/* Start of Mobile Header  */}
        <MobileHeader hTitle="Manage Job" />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Manage Jobs"}
          back={BackBtn}
          backbtn
          searchedKey={searchedKey}
          onFieldChange={handleInputChange}
        />
        {/* End of desktop Header  */}

        <HeaderDownLayout>
          <div className="job-nav-containers">
            <NativeLink className="job-nav">
              <div
                onClick={() =>
                  handleNavigationClick("/portal/manage-jobs", "manage-job")
                }
                className={
                  isCardActives("/portal/manage-jobs") ||
                  activeState === "manage-job"
                    ? "active job-nav-link"
                    : "job-nav-link"
                }
              >
                Active Jobs
              </div>
              <div
                onClick={() => history.push("/portal/archievedJobs")}
                className="job-nav-link"
              >
                Archived Jobs
              </div>
              <div
                onClick={() => history.push("/portal/drafts")}
                className="o-desk job-nav-link"
              >
                Draft{" "}
              </div>
            </NativeLink>
            {userRole ? (
              <Wrappers>
                <div className="o-desk">
                  <button className="button2" onClick={onSubmit}>
                    <img src={PlusHover} alt="img" className="plus-icon2" />
                    <img src={Plus} alt="img" className="plus-icon" />
                    Post a Job
                  </button>
                </div>
              </Wrappers>
            ) : (
              ""
            )}
          </div>
        </HeaderDownLayout>
      </HeaderLayout>


      <div
        className={
          isPopupOpen
            ? "job-card-app-wraps job-card-app-wrap"
            : "job-card-app-wrap"
        }
      >
        {loading ? (
          <div style={{ top: "160px", position: "relative" }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <ShimmerLoading key={index} />
            ))}
          </div>
        ) : data && data?.length > 0 ? (
          <div
            className="job-card-container"
          
          >
            {data &&
              data.length &&
              data
                .filter((i) => {
                  // Add your filtering logic here for 'data'
                  return (
                    i.status === "active" && // Filter by status (e.g., only 'active')
                    i.jobTitle
                      .toLowerCase()
                      .includes(searchKeyword.toLowerCase()) // Filter by job title
                  );
                })
                .map((i) => {
                  var dateString = currentDate;
                  var newdate = new Date(dateString);

                  var formatedDateTime = `${newdate.toLocaleDateString()} ${newdate.toLocaleTimeString()}`;

                  var dateString2 = i.scheduleDate;
                  var newdate2 = new Date(dateString2);
                  const formatDate = (inputDate) => {
                    const date = new Date(inputDate);
                    const day = date.getDate();
                    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
                    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year
                    const time = date.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    });

                    return `${day}/${month}/${year} ${time}`;
                  };
                  const formattedDate3 = `${newdate2.toLocaleDateString()} ${newdate2.toLocaleTimeString()}`;
                  const formattedDate = formatDate(formattedDate3);

                  return (
                    <div className="int-rows">
                      <CardWrapper
                        className={
                          isPopupOpen ? "job-card-wrap" : "job-card-wrapper"
                        }
                        onClick={() => openPopup(i)}
                      >
                        <div
                          className={activeCard === i._id ? "underline" : ""}
                        ></div>
                        <div>
                          <ContentWrapper
                            className="job-content-wrapper"
                            onClick={() => openPopup(i)}
                          >
                            <div className="company-logo-container">
                              {i.companyName && i.companyName.companyLogo ? (
                                <img
                                  src={
                                    i.companyName && i.companyName.companyLogo
                                  }
                                  alt="img"
                                  className="company-logo"
                                />
                              ) : (
                                <img
                                  src={Image}
                                  alt="img"
                                  className="company-logo"
                                />
                              )}
                            </div>

                            <Wrapper className="job-title-wrapper">
                              <JobTitle
                                key={i._id}
                                onClick={() => openPopup(i)}
                                className="job-title"
                              >
                                {i.jobTitle}
                              </JobTitle>

                              <CompanyTitle
                                className="company-title"
                                onClick={() =>
                                  history.push({
                                    pathname: `/portal/other-company-view/${i.companyName?.comID}`,
                                  })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {i.companyName && i.companyName.value}
                              </CompanyTitle>
                              <p className="timestamp">
                                <TimeAgo timestamp={i.timestamps?.createdAt} />
                              </p>
                            </Wrapper>
                            <DateWrapper1 className="date-toggle-wrapper">
                              {i.scheduleDate ? (
                                formatedDateTime >= formattedDate3 ? (
                                  <>
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Toggle
                                        checked={i.status === "active"}
                                        onChange={() =>
                                          handleToggleChange(i._id)
                                        }
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    <PostedJob>Scheduled On: </PostedJob>
                                    <PostedOne>{formattedDate}</PostedOne>
                                  </div>
                                )
                              ) : (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Toggle
                                    color="#955cca"
                                    checked={i.status === "active"}
                                    onChange={() => handleToggleChange(i._id)}
                                  />
                                </div>
                              )}
                            </DateWrapper1>
                          </ContentWrapper>
                          <HrLines className="job-divider" />
                          <div className="d-flex align-items-center justify-content-between">
                            <JobDetail className="job-details">
                              <IconTitle className="icon-title">
                                <img
                                  src={iExp}
                                  className="jt-icon"
                                  alt="icon"
                                />
                                <span>
                                  {" "}
                                  Exp{" "}
                                  {i.experienceRequired2
                                    ? `${i.experienceRequired} - ${i.experienceRequired2} yrs`
                                    : i.experienceRequired
                                      ? `${i.experienceRequired} yrs`
                                      : ""}{" "}
                                </span>
                              </IconTitle>

                              <IconTitle className="icon-title">
                                <img
                                  src={iSalary}
                                  className="jt-icon"
                                  alt="icon"
                                />
                                <span>{`${i.salaryFrom}L - ${i.salaryTo}L`}</span>
                              </IconTitle>
                              <IconTitle className="icon-title">
                                <img
                                  src={iLocation}
                                  className="jt-icon"
                                  alt="icon"
                                />
                                <span>
                                  {i.location?.substring(0, 8)}
                                  {i.location?.length > 8 ? "..." : ""}
                                </span>
                              </IconTitle>

                              <IconTitle className="icon-title">
                                <img
                                  src={iHome}
                                  className="jt-icon"
                                  alt="icon"
                                />
                                <span>{i.workMode}</span>
                              </IconTitle>
                            </JobDetail>
                          </div>

                          <div className="jc-footer">
                            <DateWrapper className="applicant-wrap">
                              <Applicant
                                className="applicant-text"
                                onClick={() =>
                                  history.push(
                                    `/portal/view-applicants/${i._id}`
                                  )
                                }
                              >
                                <img
                                  src={iUserGroup}
                                  className="jt-icon"
                                  alt="icon"
                                />
                                <span>
                                  {" "}
                                  {`${i.applicants.length}`} Application(s)
                                </span>
                              </Applicant>
                            </DateWrapper>
                          </div>
                        </div>
                      </CardWrapper>
                    </div>
                  );
                })}
          </div>
        ) : (
          <img src={noData} className="nodata-center" alt="icon" />
        )}
        {isPopupOpen && selectedJobData ? (
          <div className={isScrolled ? "job-app-wrap" : "job-app-wraps"}>
            <DialogContent style={{ padding: "0" }} className="app-card">
              <ViewApplicant
                jobData={selectedJobData}
                setIsOpen={() => {
                  setPopupOpen(false);
                  setActiveCard(null);
                }}
              />
            </DialogContent>
            <div className="job-prev-btn">
              <button
                className="button5"
                style={{
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                onClick={updateArchived}
              >
                <img src={Archieve} alt="img" />
                Archive
              </button>
              <button
                className="button3"
                style={{ width: "150px" }}
                onClick={handleSubmit}
              >
                View Applicant
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={openJobOness}
        PaperProps={{
          style: {
            width: "100%",
            height: "fit-content",
            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        ></DialogTitle>
        <DialogContent>
          <Prefill
            closeJobOne={closeJobpageOnss}
            closeAll={closeAllssActive}
            jobId={jobId}
            shouldUpdate={shouldUpdates}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobOne}
        PaperProps={{
          style: {
            width: "100%",
            height: "fit-content",
            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <NewJob
            closeJobOne={closeJobpageOn}
            closeAll={closeAll}
            newOne={goToJobOne}
            openNewJob={openJobOne}
            isCardActive={activeState}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobTwo}
        PaperProps={{
          style: {
            width: "100%",
            height: "fit-content",
            borderRadius: "24px",
            maxWidth: "460px",
          },
        }}
        halfWidth
        maxWidth="sm"
      >
        <HrLines className="job-divider" style={{ margin: "0px" }} />
        <DialogContent>
          <JobInfo
            jobId={jobId}
            previewJobId={updatePreview}
            close={updatePreviews}
            previewSaveJobId={updateSavePreviews}
            goToJobOne={goToJobOne}
            closeAll={closeAll}
            openSaves={closeAll}
            closePopups={() => setOpenJobTwo(false)}
            goToOnePart={closeJobpageOnssYes}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobPreview}
        PaperProps={{
          style: {
            width: "100%",
            height: "fit-content",
            borderRadius: "24px",
            padding: "0px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent
          PaperProps={{
            style: { padding: "0px" },
          }}
        >
          <PreviewJob
            previewId={previewId}
            goToJobTwo={goToJobtwo}
            closePreview={() => setOpenJobPreview(false)}
            callAlls={handleCall}
            callAll={handleCall}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text className="modal-title-sub">
            Are You Sure You Want To Log Out?
          </Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Jobs;
