/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Image from "../../images/company.png";
import MobileHeader from "../mobileHeader";
import DesktopHeader from "../desktopHeader";
import iExp from "../../images/icons/exp.svg";
import noData from "../../images/NoData.svg";
import iSalary from "../../images/icons/salary.svg";
import iLocation from "../../images/icons/location.svg";
import iHome from "../../images/icons/home.svg";

import iTrash from "../../images/icons/trash-can-btn.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Toggle from "react-toggle";

import "./buttonStyle.css";
import "./jobs.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, DialogTitle } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewApplicant from "./archievedJobApplication";

import NewJob from "./addNewJob";
import JobInfo from "./addJobInformation";

import { Amplify, Auth } from "aws-amplify";
import Common from "../../utils/common";
import Prefill from "./addPrefillNewJob";

import QuestionImg from "../../images/solar_question-square-bold-duotone.svg";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import Spinner from "react-bootstrap/Spinner";
import Settings from "../settings";
import CloseIcons from "../../images/CloseIcon.svg";
import ArchivedPreview from "./archivedPreview";
import Plus from "../../images/Normal_State_Plus.svg";
import PlusHover from "../../images/Hover_State_Plus.svg";
import ShimmerLoading from "../interview/shimmerloading";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;

const Button3 = styled.div`
  background: #36a47c;
  border-radius: 10px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const Button5 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px;
  height: 43px;
  position: relative;
  // top: 3px;
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Button7 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 0px 10px;
  height: 43px;
  position: relative;
  // top: 3px;
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;

  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;

const ContentWrapper = styled.div``;

const Wrapper = styled.div``;
const Wrappers = styled.div`
  display: flex;
  align-items: center;
`;
const JobDetail = styled.div``;

const JobTitle = styled.div``;
const CompanyTitle = styled.div``;
const IconTitle = styled.div``;

const DateWrapper1 = styled.div``;

const HrLines = styled.div``;

const HeaderLayout = styled.div``;
const HeaderDownLayout = styled.div``;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const NativeLink = styled.div``;
const ButtonLink = styled.div`
  cursor: pointer;
`;
const CardWrapper = styled.div``;
const Jobs = () => {
  const history = useHistory();
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const [data, setData] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [jobId, setJobId] = useState("");
  const [openJobOne, setOpenJobOne] = useState(false);
  const [openJobTwo, setOpenJobTwo] = useState(false);
  const [openJobPreview, setOpenJobPreview] = useState(false);
  const [previewId, setPreviewId] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobActiveId, setJobActive] = useState(null);
  const [jobIdToDeleteData, setJobIdToDeleteData] = useState(null);
  const [roleAdmin, setRoleAdmin] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);

  const location = useLocation();
  const [openJobOness, setOpenJobOness] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [shouldUpdates, setShouldUpdates] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const [activeCard, setActiveCard] = useState(null);
  const { state } = useLocation();
  const isCardActive = state?.isCardActive;
  const openNewPopup = state?.openNewJob;
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);

  function onSubmit() {
    setOpenJobOne(true);
  }

  const closeAll = () => {
    setOpenJobOne(false);
  };
  // const getAllArchived = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${process.env.PUBLIC_URL}/xakal/get-archived`
  //     );
  //     if (response.data) {
  //       const newJobs = response.data.job;

  //       setData(newJobs);
  //     }
  //   } catch (err) {
  //     console.log("Error fetching archived details:", err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const getAllArchived = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-archived`
      );
      console.log(response.data, "response");
      if (response.data) {
        const newJobs = response.data.job;

        setData(newJobs);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const prefill = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      );
      if (response && response.data && response.data.aboutOne) {
        setUserRole(response.data.aboutOne.userType.includes("hr"));
        setRoleAdmin(response.data.aboutOne.userType.includes("admin"));
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    prefill();
  }, []); // Run this only on mount

  useEffect(() => {
    // Call getAllArchived whenever roleAdmin changes
    getAllArchived();
  }, []); // Dependency on roleAdmin

  const deleteOneArchivedJob = async (jobId) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/archived/${jobId}`
      );
      setData(data.filter((item) => item._id !== jobId));
      setOpen4(false);
      setPopupOpen(false);
      toast.success("Job Deleted Successfully...");
    } catch (error) {
      console.error("Error deleting archived job:", error);
    }
  };
  const handleToggleChange = async (jobId) => {
    const job = data.find((item) => item._id === jobId);

    if (!job) {
      console.error("Job not found.");
      return;
    }

    const newStatus = job.status === "archived" ? "active" : "archived";
    try {
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/active-manage/${job._id}`,
        {
          status: newStatus,
        }
      );

      if (response.status === 200) {
        const updatedData = data.map((item) =>
          item._id === job._id ? { ...item, status: newStatus } : item
        );
        setData(updatedData);
        toast.success("Job Active Successfully...");
      } else {
        console.error("Failed to update status");
      }

      setOpen(false);
      getAllArchived();
      setPopupOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const openPopup = (jobData) => {
    setSelectedJobData(jobData);
    setPopupOpen(true);
    setActiveCard(jobData._id);
  };
  const updateActive = async (activeId) => {
    // e.preventDefault();
    try {
      await axios.put(
        `${process.env.PUBLIC_URL}/xakal/manage/${selectedJobData._id}/active`
      );

      setOpen3(false);
      toast.success("Job Active Successfully...");
      setPopupOpen(false);
      getAllArchived();
    } catch (error) {
      console.log(error);
    }
  };
  const getAllDraft = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-draft`
      );
      if (response.data) {
        setData(response.data.job);
      }
    } catch (err) {
      console.log("Error fetching draft details:", err);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const currentDate = new Date();

  const closeJobpageOn = (id) => {
    setOpenJobOne(false);
    setOpenJobTwo(true);
    setJobId(id);
  };
  const closeJobpageOnArchived = (id) => {
    setOpenJobOness(false);
    setOpenJobTwo(true);
    setJobId(id);
    setShouldUpdates(true);
  };
  const updatePreview = (id) => {
    setOpenJobTwo(false);
    setOpenJobPreview(true);
    setPreviewId(id);
  };
  const updateArchivedPreview = (id) => {
    setOpenJobTwo(false);
    setOpenJobPreview(true);
    setPreviewId(id);
  };
  const updatePreviews = () => {
    setOpenJobTwo(false);
    setOpenJobPreview(false);
    getAllDraft();
    getAllArchived();
  };
  const goToJobOne = () => {
    setOpenJobOness(true);
    setOpenJobTwo(false);
  };
  const closeJobpageOnss = (id) => {
    setOpenJobOness(false);
    setOpenJobTwo(true);
    setJobId(id);
    setShouldUpdates(true);
  };
  const closeJobpageOnssYes = (id) => {
    setOpenJobOness(true);
    setOpenJobTwo(false);
    setJobId(id);
    setShouldUpdates(true);
  };
  const goToJobtwo = () => {
    setOpenJobTwo(true);
    setOpenJobPreview(false);
  };

  const closeAllss = () => {
    setOpenJobOness(false);
  };
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };
  const handleInputChange = (event) => {
    setSearchKeyword(event.target.value);
  };
  const getAll = () => {
    getAllArchived();
    setOpenJobPreview(false);
  };

  const BackBtn = () => {
    history.push("./jobs");
  };

  const searchedKey = () => {};
  const openRepost = () => {
    setOpen(false);
    setOpenJobOness(true);
  };
  const openSaves = () => {
    setOpenJobTwo(false);
    setOpen3(false);
  };

  const handleArchived = (i) => {
    setJobActive(i);
    setOpen(true);
    setPopupOpen(false);
  };
  const [activeState, setActiveState] = useState(() => {
    // Initialize active state based on current path
    switch (location.pathname) {
      case "/portal/archievedJobs":
        return "archived";
      case "/portal/drafts":
        return "draft";

      default:
        return "manage-job"; // Default state for "/portal/jobs"
    }
  });
  const handleNavigationClick = (path, stateValue) => {
    history.push(path);
    setActiveState(stateValue);
  };
  useEffect(() => {
    if (isCardActive === "archived") {
      setActiveState(isCardActive);
      setOpenJobOne(openNewPopup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ToastContainer />
      <HeaderLayout className="header-layout">
        <MobileHeader hTitle="Manage Job" />

        <DesktopHeader
          title={"Manage Jobs"}
          searchedKey={searchedKey}
          onFieldChange={handleInputChange}
          back={BackBtn}
          backbtn
        />

        <HeaderDownLayout>
          <div className="job-nav-containers">
            <NativeLink className="job-nav">
              <div
                onClick={() => history.push("./manage-jobs")}
                className="job-nav-link"
              >
                Active Jobs
              </div>
              <div
                onClick={() =>
                  handleNavigationClick("/portal/archievedJobs", "archived")
                }
                className={
                  location.pathname === "/portal/archievedJobs" ||
                  activeState === "archived"
                    ? "active job-nav-link"
                    : "job-nav-link"
                }
              >
                Archived Jobs
              </div>
              <div
                onClick={() => history.push("./drafts")}
                className="o-desk job-nav-link"
              >
                Draft{" "}
              </div>
            </NativeLink>
            {userRole ? (
              <Wrappers>
                <div className="o-desk">
                  <button className="button2" onClick={onSubmit}>
                    <img src={PlusHover} alt="img" className="plus-icon2" />
                    <img src={Plus} alt="img" className="plus-icon" />
                    Post a Job
                  </button>
                </div>
              </Wrappers>
            ) : (
              ""
            )}
          </div>
        </HeaderDownLayout>
      </HeaderLayout>
      <div
        className={
          isPopupOpen
            ? "job-card-app-wraps job-card-app-wrap"
            : "job-card-app-wrap"
        }
      >
        <>
          {loading ? (
            <div style={{ top: "160px", position: "relative" }}>
              {Array.from({ length: 4 }).map((_, index) => (
                <ShimmerLoading key={index} />
              ))}
            </div>
          ) : data && data?.length > 0 ? (
            <div>
              {data
                .filter((i) => {
                  return (
                    i.status === "archived" &&
                    i.jobTitle
                      .toLowerCase()
                      .includes(searchKeyword.toLowerCase())
                  );
                })
                .map((i, ind) => {
                  return (
                    <div className="int-rows">
                      <CardWrapper
                        className={
                          isPopupOpen ? "job-card-wrap" : "job-card-wrapper"
                        }
                        onClick={() => openPopup(i)}
                      >
                        <div
                          className={activeCard === i._id ? "underline" : ""}
                        ></div>
                        <div>
                          <ContentWrapper className="job-content-wrapper">
                            <div className="company-logo-container">
                              {i.companyName && i.companyName.companyLogo ? (
                                <img
                                  src={
                                    i.companyName && i.companyName.companyLogo
                                  }
                                  alt="img"
                                  className="company-logo"
                                />
                              ) : (
                                <img
                                  src={Image}
                                  alt="img"
                                  className="company-logo"
                                />
                              )}
                            </div>
                            <Wrapper className="job-title-wrapper">
                              <JobTitle className="job-title" key={i._id}>
                                {i.jobTitle}
                              </JobTitle>
                              <CompanyTitle
                                className="company-title"
                                onClick={() =>
                                  history.push({
                                    pathname: `/portal/other-company-view/${i.companyName?.comID}`,
                                  })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {i.companyName && i.companyName.value}
                              </CompanyTitle>
                              <p className="timestamp">
                                <TimeAgo timestamp={i.timestamps?.createdAt} />
                              </p>
                            </Wrapper>

                            <DateWrapper1 className="date-toggle-wrapper">
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();

                                  setJobIdToDeleteData(i._id);
                                  setOpen4(true);
                                }}
                                src={iTrash}
                                className="bs-trash"
                                style={{ cursor: "pointer" }}
                                alt="icon"
                              />
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Toggle
                                  className=""
                                  key={i._id}
                                  checked={i.status !== "archived"}
                                  onClick={() => {
                                    handleArchived(i?._id);
                                  }}
                                />
                              </div>
                            </DateWrapper1>
                          </ContentWrapper>
                          <HrLines className="job-divider" />

                          <div className="d-flex align-items-center justify-content-between">
                            <JobDetail className="job-details">
                              <IconTitle className="icon-title">
                                <img
                                  src={iExp}
                                  className="jt-icon"
                                  alt="icon"
                                />
                                <span>
                                  {" "}
                                  Exp{" "}
                                  {i.experienceRequired2
                                    ? `${i.experienceRequired} - ${i.experienceRequired2} yrs`
                                    : i.experienceRequired
                                      ? `${i.experienceRequired} yrs`
                                      : ""}{" "}
                                </span>
                              </IconTitle>

                              <IconTitle className="icon-title">
                                <img
                                  src={iSalary}
                                  className="jt-icon"
                                  alt="icon"
                                />
                                <span>{`${i.salaryFrom}L - ${i.salaryTo}L`}</span>
                              </IconTitle>
                              <IconTitle className="icon-title">
                                <img
                                  src={iLocation}
                                  className="jt-icon"
                                  alt="icon"
                                />
                                <span>
                                  {i.location?.substring(0, 8)}
                                  {i.location?.length > 8 ? "..." : ""}
                                </span>
                              </IconTitle>

                              <IconTitle className="icon-title">
                                <img
                                  src={iHome}
                                  className="jt-icon"
                                  alt="icon"
                                />
                                <span>{i.workMode}</span>
                              </IconTitle>
                            </JobDetail>
                          </div>
                        </div>
                      </CardWrapper>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div>
              <img src={noData} className="nodata-center" alt="icon" />
            </div>
          )}
          {isPopupOpen && selectedJobData ? (
            <div className={isScrolled ? "job-app-wrap" : "job-app-wraps"}>
              <DialogContent style={{ padding: "0" }} className="app-card">
                <ViewApplicant
                  jobData={selectedJobData}
                  setIsOpen={() => {
                    setPopupOpen(false);
                    setActiveCard(null);
                  }}
                />
              </DialogContent>
              <div className="job-prev-btn">
                <Button5
                  onClick={() => {
                    setJobIdToDeleteData(selectedJobData._id);
                    setOpen4(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Button5>
                <button
                  className="button3"
                  onClick={() => {
                    setJobActive(selectedJobData._id);
                    setOpen3(true);
                  }}
                >
                  Active
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      </div>

      <Dialog
        open={open}
        PaperProps={{
          style: {
            borderRadius: "20px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            overflow: "hidden",
            padding: "5px 10px",
            backgroundColor: "#F4F4F6",
          },
        }}
        halfWidth
        maxWidth="md"
      >
        <DialogContent
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div className="d-flex  align-items-center" style={{ gap: " 8px" }}>
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                gap: " 8px",
                background: "white",
                flexShrink: "0",
              }}
            >
              <img
                src={QuestionImg}
                style={{ marginTop: "2px", marginLeft: "9px" }}
                alt="img"
              />
            </div>
            <JobTitle
              style={{
                fontFamily: "Inter-Medium,sans-serif",
                fontSize: "14px",
                fontweight: "normal",
                gap: "10px",
                display: "flex",
                color: "black",

                top: "-5px",
              }}
            >
              Are you sure you want to activate this job?
            </JobTitle>
          </div>

          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                gap: "10px",
                margin: "0 0px 0 20px",
              }}
            >
              <Button7 onClick={openRepost} style={{ cursor: "pointer" }}>
                Edit and Reopen
              </Button7>
              <Button3 onClick={() => handleToggleChange(jobActiveId)}>
                Yes, Reopen
              </Button3>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open4}
        PaperProps={{
          style: {
            borderRadius: "16px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#FFDAE0",
            padding: "5px 10px",
          },
        }}
        halfWidth
        maxWidth="md"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",

            left: "5px",
            top: "-3px",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img src={DeleteImg} alt="img" style={{ marginLeft: "7px" }} />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              gap: "10px",
              display: "flex",

              top: "-6px",
              color: "#40444F",
            }}
          >
            <span className="">Are you sure you want to delete this job?</span>
          </JobTitle>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                gap: "10px",
                margin: "0 0px 0 20px",
              }}
            >
              <Button5
                onClick={() => setOpen4(false)}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Button5>
              <Button6 onClick={() => deleteOneArchivedJob(jobIdToDeleteData)}>
                Yes, Delete
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open3}
        PaperProps={{
          style: {
            borderRadius: "20px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",

            overflow: "hidden",
            padding: "5px 10px",
            backgroundColor: "#F4F4F6",
          },
        }}
        halfWidth
        maxWidth="md"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div className="d-flex  align-items-center" style={{ gap: " 8px" }}>
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                gap: " 8px",
                background: "white",
              }}
            >
              <img
                src={QuestionImg}
                style={{ marginTop: "2px", marginLeft: "9px" }}
                alt="img"
              />
            </div>
            <JobTitle
              style={{
                fontFamily: "Inter-Medium,sans-serif",
                fontSize: "14px",
                fontweight: "normal",
                gap: "10px",
                display: "flex",
                color: "black",

                top: "-5px",
              }}
            >
              Are you sure you want to activate this job?
            </JobTitle>
          </div>

          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                gap: "10px",
                margin: "0 0px 0 20px",
              }}
            >
              <Button5 onClick={openRepost} style={{ cursor: "pointer" }}>
                Edit and Reopen
              </Button5>
              <Button3 onClick={() => updateActive(jobActiveId)}>
                Yes, Reopen
              </Button3>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobOne}
        PaperProps={{
          style: {
            width: "100%",

            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        ></DialogTitle>
        <DialogContent>
          <NewJob
            closeJobOne={closeJobpageOn}
            closeAll={closeAll}
            openNewJob={openJobOne}
            isCardActive={activeState}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobTwo}
        PaperProps={{
          style: {
            width: "100%",

            borderRadius: "24px",
            maxWidth: "460px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <JobInfo
            jobId={jobId}
            jobArchivedId={jobId}
            previewJobId={updatePreview}
            jobArchivedPreviewId={updateArchivedPreview}
            close={updatePreviews}
            openSaves={openSaves}
            goToJobOne={goToJobOne}
            goToOnePart={closeJobpageOnssYes}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobPreview}
        PaperProps={{
          style: {
            width: "100%",

            borderRadius: "24px",
            maxWidth: "610px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <ArchivedPreview
            previewId={previewId}
            archivedPreviewId={previewId}
            goToJobTwo={goToJobtwo}
            closePreview={() => setOpenJobPreview(false)}
            callAll={getAll}
            openPopup={() => setPopupOpen(false)}
            closeDelete={() => setOpen3(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobOness}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "540px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        ></DialogTitle>
        <DialogContent>
          <Prefill
            closeJobOne={closeJobpageOnss}
            closeAll={closeAllss}
            jobId={jobId}
            shouldUpdate={shouldUpdates}
            jobArchivedId={jobActiveId}
            closeArchivedJob={closeJobpageOnArchived}
            setOpens={() => setOpen3(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Jobs;
